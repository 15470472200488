import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useCallback} from 'react';
import {useAppSelector} from '../store/hooks';
import {DidApiParams} from '../types';

const useDidApi = () => {
  const {organization} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  console.log(organization);

  const didApi = useCallback(
    <P extends object, R>(api: (params: DidApiParams<P>) => R, payload: P): R => {
      return api({
        payload,
        headers: {
          'x-organization-id': organization?.id || '',
          'x-resource-group-id': selectedResourceGroup.id
        }
      });
    },
    [organization?.id, selectedResourceGroup]
  );

  return didApi;
};

export default useDidApi;
