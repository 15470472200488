import {
  makeStyles,
  TagPicker as FluentTagPicker,
  TagPickerList,
  TagPickerOption,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  Tag
} from '@fluentui/react-components';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {unassignTag as unassignTagApi, assignTag as assignTagApi} from '../fetcher';
import useToasts from '../hooks/useToasts';
import {unassignTag, assignTag} from '../reducers/tagsSlice';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';

interface TagPickerProps {
  readonly enqueueTagRequest: (promise: () => Promise<void>) => void;
  readonly serial: string;
  readonly scenarioId: number;
}

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  }
});

export const TagPicker = ({enqueueTagRequest, serial, scenarioId}: TagPickerProps) => {
  const styles = useStyles();
  const {tags, loading} = useAppSelector(state => state.tagsSlice);
  const {organization} = usePopulatedTopbarValues();
  const {dispatchAppToast} = useToasts();
  const dispatch = useAppDispatch();

  const assignedTagNames = tags
    .filter(t => t.devices.find(d => d.serial === serial && d.scenarioIds.includes(scenarioId)))
    .map(t => t.name);

  const unassignedTags = tags.filter(t => !assignedTagNames.includes(t.name));

  const onUnassign = (tagName: string) => {
    if (!organization?.id) {
      return;
    }
    enqueueTagRequest(() =>
      unassignTagApi({
        orgId: organization.id,
        payload: {
          tagName,
          scenarioId: scenarioId,
          serial: serial
        }
      }).then(success => {
        if (!success) {
          dispatchAppToast({
            title: 'Unexpected Error',
            intent: 'error',
            message: 'Failed to unassign tag, please reload the page'
          });
        }
      })
    );
    dispatch(
      unassignTag({
        name: tagName,
        serial,
        scenarioId
      })
    );
  };

  const onAssign = (tagName: string) => {
    if (!organization?.id) {
      return;
    }
    enqueueTagRequest(() =>
      assignTagApi({
        orgId: organization.id,
        payload: {
          tagName,
          scenarioId,
          serial
        }
      }).then(success => {
        if (!success) {
          dispatchAppToast({
            title: 'Unexpected Error',
            intent: 'error',
            message: 'Failed to assign tag, please reload the page'
          });
        }
      })
    );
    dispatch(
      assignTag({
        name: tagName,
        serial,
        scenarioId
      })
    );
  };

  return (
    <FluentTagPicker
      disabled={loading !== 'succeeded'}
      onOptionSelect={(_, data) => {
        if (data.value === 'no-options') return;
        assignedTagNames.includes(data.value) ? onUnassign(data.value) : onAssign(data.value);
      }}
      selectedOptions={assignedTagNames}
    >
      <TagPickerControl className={styles.fullWidth} data-testid={'tag-picker-' + scenarioId}>
        <TagPickerGroup>
          {assignedTagNames.sort().map(tagName => (
            <Tag
              key={'tag-picker-selected-' + tagName}
              value={tagName}
              data-testid={'tag-picker-selected-' + tagName}
            >
              {tagName}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput />
      </TagPickerControl>
      <TagPickerList>
        {unassignedTags.length > 0 ? (
          unassignedTags.map(tag => (
            <TagPickerOption
              value={tag.name}
              key={'tag-picker-option-' + tag.name}
              data-testid={'tag-picker-option-' + tag.name}
            >
              {tag.name}
            </TagPickerOption>
          ))
        ) : (
          <TagPickerOption value="no-options">No options available</TagPickerOption>
        )}
      </TagPickerList>
    </FluentTagPicker>
  );
};
