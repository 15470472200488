import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  Tab,
  TabList,
  tokens,
  Text
} from '@fluentui/react-components';
import {embedDashboard} from '@superset-ui/embedded-sdk';
import {fetchDashboardData, fetchGuestToken} from '../../fetcher';
import {useEffect, useState} from 'react';
import {DashboardInfo, ApiResponseError} from '../../types';
import {bundleIcon, QuestionCircle24Filled, QuestionCircle24Regular} from '@fluentui/react-icons';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from 'urql';
import {AllResourceGroupsDocument} from '../../api/__generated__/graphql';
import Loader from '../Loader';
import {MainEmptyView} from '@axiscommunications/fluent-empty-view';

const useStyles = makeStyles({
  wrapper: {
    flexGrow: 1,
    background: 'white'
  },
  supersetcontainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  hidden: {
    display: 'none'
  },
  header: {
    // Attempt to mimic superset dashboard header
    color: '#333',
    fontFamily: 'Inter,Helvetica,Arial',
    lineHeight: 1.4,
    fontSize: '21px',
    fontWeight: 600
  },
  topBar: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingRight: tokens.spacingHorizontalXXL,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const HelpAreaIcon = bundleIcon(QuestionCircle24Filled, QuestionCircle24Regular);

const getDashboardTitle = (orgName?: string, resourceGroupName?: string) => {
  if (orgName && resourceGroupName && orgName !== resourceGroupName) {
    return `- ${orgName} / ${resourceGroupName}`;
  } else if (orgName) {
    return `- ${orgName}`;
  }
  return '';
};

const Superset = () => {
  const {organization, language} = usePopulatedTopbarValues();
  const styles = useStyles();
  const [selectedDashboardUuid, setSelectedDashboardUuid] = useState<DashboardInfo>();
  const [availableDashboards, setAvailableDashboards] = useState<DashboardInfo[]>([]);
  const [apiError, setApiError] = useState<ApiResponseError>();
  const [searchParams] = useSearchParams();
  const [organizationId, setOrganizationId] = useState<string>();
  const [resourceGroupId, setResourceGroupId] = useState<string>();
  const [showDashboard, setShowDashboard] = useState<boolean>();
  const [resourceGroups] = useQuery({
    query: AllResourceGroupsDocument,
    variables: {organizationArn: organization?.arn || ''},
    pause: !organization?.arn
  });

  useEffect(() => {
    setOrganizationId(searchParams.get('organizationId') || '');
    setResourceGroupId(searchParams.get('resourceGroupId') || '');
  }, [searchParams]);

  useEffect(() => {
    if (!organizationId || !resourceGroupId) {
      return;
    }
    fetchDashboardData({
      orgId: organizationId,
      payload: {resourceGroupId}
    }).then(response => {
      if ('error' in response) {
        setApiError(response.error);
        return;
      }
      const dashboardInfoSorted = response.dashboards.sort((a, b) => a.name.localeCompare(b.name));
      if (dashboardInfoSorted[0]) {
        setSelectedDashboardUuid(dashboardInfoSorted[0]);
      }
      setAvailableDashboards(dashboardInfoSorted);
    });
  }, [organizationId, resourceGroupId]);

  useEffect(() => {
    setApiError(undefined);
  }, [resourceGroupId, organizationId]);

  useEffect(() => {
    const supersetContainer =
      selectedDashboardUuid && document.getElementById(selectedDashboardUuid.uuid);
    if (supersetContainer && organizationId && resourceGroupId) {
      const fetchGuestTokenCallback = async () =>
        fetchGuestToken({
          orgId: organizationId,
          payload: {
            dashboardId: selectedDashboardUuid.uuid,
            resourceGroupId: resourceGroupId
          }
        }).then(resp => {
          if ('error' in resp) {
            setApiError(resp.error);
            return '';
          }
          return resp.guestToken;
        });

      const supersetDashboard = embedDashboard({
        id: selectedDashboardUuid.uuid,
        supersetDomain: selectedDashboardUuid.domain,
        mountPoint: supersetContainer,
        fetchGuestToken: fetchGuestTokenCallback,
        dashboardUiConfig: {
          urlParams: {lang: language}
        }
      });
      supersetDashboard.then(() => setShowDashboard(true)).catch(console.error);
      const dashboard = supersetContainer.children[0];
      if (dashboard) {
        dashboard.setAttribute('width', '100%');
        dashboard.setAttribute('height', '100%');
        dashboard.setAttribute('frameBorder', '0');
        dashboard.setAttribute(
          'data-testid',
          selectedDashboardUuid?.name.replace(/\s/g, '-').toLowerCase() + '-superset-iframe'
        );
      }
    }
  }, [selectedDashboardUuid, organizationId, resourceGroupId, language]);

  const resourceGroupName = resourceGroups.data?.allChildren?.children.find(
    rg => rg.id === resourceGroupId
  )?.name;

  return (
    <div className={styles.wrapper} data-testid="superset-page">
      <div className={styles.topBar}>
        <h1 className={styles.header}>
          Data Insights Dashboard {getDashboardTitle(organization?.label, resourceGroupName)}
        </h1>
        <Dialog>
          <DialogTrigger disableButtonEnhancement>
            <Button icon={<HelpAreaIcon />} appearance="subtle" />
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Help</DialogTitle>
              <DialogContent>
                <p>Note that:</p>
                <ul>
                  <li>Charts are automatically refreshed every 15 minutes</li>
                  <li>Each chart may refreshed at a different time.</li>
                  <li>The underlying data is refreshed every 15 minutes.</li>
                  <li>It may take up to 30 minutes for an event to affect dashboard.</li>
                </ul>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="primary">Close</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>

      <div className={showDashboard ? styles.supersetcontainer : styles.hidden}>
        <TabList
          selectedValue={selectedDashboardUuid?.uuid || ''}
          onTabSelect={(_, data) =>
            setSelectedDashboardUuid(
              availableDashboards.find(dashboard => dashboard.uuid === data.value)
            )
          }
        >
          {availableDashboards.map(dashboard => (
            <Tab key={'tab' + dashboard.name} value={dashboard.uuid}>
              {dashboard.name}
            </Tab>
          ))}
        </TabList>
        <div
          className={styles.supersetcontainer}
          id={selectedDashboardUuid?.uuid}
          data-testid={
            selectedDashboardUuid?.name.replace(/\s/g, '-').toLowerCase() + '-superset-container'
          }
        />
      </div>
      {!showDashboard && !apiError && <Loader />}
      {apiError === ApiResponseError.NO_DEVICES && (
        <MainEmptyView
          illustration="devices"
          title="No devices are onboarded in this organization/folder"
        />
      )}
      {apiError === ApiResponseError.UNKNOWN && (
        <MainEmptyView illustration="no-connection" title="Unknown error">
          <Text>An unknown error has occurred. Please try again later.</Text>
        </MainEmptyView>
      )}
      {apiError === ApiResponseError.NO_DATA && (
        <MainEmptyView illustration="data" title="No data found">
          <Text>
            No data has been sent from devices in this organization/folder. It can take up to 15
            minutes for data to become visible.
          </Text>
        </MainEmptyView>
      )}
    </div>
  );
};

export default Superset;
