import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DidApiParams, FetchTagsPayload, ResponseWithError, Tag} from '../types';
import {fetchTags as fetchTagsApi} from '../fetcher';

export const fetchTags = createAsyncThunk(
  'users/fetchTagsStatus',
  async (
    arg: {
      orgArn: string;
      didApi: (
        api: (params: DidApiParams<FetchTagsPayload>) => Promise<ResponseWithError<Tag[]>>,
        payload: FetchTagsPayload
      ) => Promise<ResponseWithError<Tag[]>>;
    },
    thunkAPI
  ) => {
    const resp = await arg.didApi(fetchTagsApi, {organizationArn: arg.orgArn});
    if ('error' in resp) {
      return thunkAPI.rejectWithValue(resp.error);
    }
    return resp;
  }
);

interface TagsState {
  readonly tags: Tag[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: TagsState = {
  tags: [],
  loading: 'idle'
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
    createTag: (state, action: PayloadAction<string>) => {
      state.tags.push({name: action.payload, devices: []});
    },
    deleteTag: (state, action: PayloadAction<string>) => {
      state.tags = state.tags.filter(tag => tag.name !== action.payload);
    },
    assignTag: (
      state,
      action: PayloadAction<{name: string; serial: string; scenarioId: number}>
    ) => {
      const tag = state.tags.find(tag => tag.name === action.payload.name);
      if (tag !== undefined) {
        const device = tag.devices.find(device => device.serial === action.payload.serial);
        if (device === undefined) {
          tag.devices.push({
            serial: action.payload.serial,
            scenarioIds: [action.payload.scenarioId]
          });
        } else {
          device.scenarioIds.push(action.payload.scenarioId);
        }
      }
    },
    unassignTag: (
      state,
      action: PayloadAction<{name: string; serial: string; scenarioId: number}>
    ) => {
      const tag = state.tags.find(tag => tag.name === action.payload.name);
      if (tag !== undefined) {
        const device = tag.devices.find(device => device.serial === action.payload.serial);
        if (device !== undefined) {
          device.scenarioIds = device.scenarioIds.filter(id => id !== action.payload.scenarioId);
        }
      }
    },
    renameTag: (state, action: PayloadAction<{oldName: string; newName: string}>) => {
      const tag = state.tags.find(tag => tag.name === action.payload.oldName);
      if (tag !== undefined) {
        tag.name = action.payload.newName;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchTags.fulfilled, (state, {payload}) => {
      state.loading = 'succeeded';
      state.tags = payload;
    });
    builder.addCase(fetchTags.rejected, state => {
      state.loading = 'failed';
      state.tags = [];
    });
    builder.addCase(fetchTags.pending, state => {
      state.loading = 'pending';
      state.tags = [];
    });
  }
});

export const {setTags, createTag, deleteTag, assignTag, unassignTag, renameTag} = tagsSlice.actions;

export default tagsSlice.reducer;
